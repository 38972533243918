import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import NKMap from "./NKMap";

import "./App.css";
import RUMap from "./RUMap";
/* eslint-disable @typescript-eslint/no-unused-vars */

function Info() {
    const { t, i18n } = useTranslation();
    const [imprint, setImprint] = useState(false);
    const imprintDiv = useRef<HTMLDivElement>(null);
    const [loading, setLoading] = useState(true);
    const [audioPlaying, setAudioPlaying] = useState(false);
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [hasBeenMuted, setHasBeenMuted] = useState(false);

    console.log('i18n.language', i18n.language)

    if (!i18n.language) {
        i18n.changeLanguage("en");
    }

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        const audio = document.querySelector("audio");
        audio && audio.pause();
        if (audio && audio.currentTime) {
            audio.currentTime = 0;
        }
    };

    // useEffect(() => {
    //     const video = document.querySelector("video");

    //     console.log('video.currentTime', video && video.currentTime)

    //   if(video && video.currentTime === 0){
    //     video.load()
    //     video.play()

    //     console.log('video playing')
    //   }

    // }, [])

    useEffect(() => {
        console.log(i18n.language);
        if (
            i18n.language !== "en" &&
            i18n.language !== "de" &&
            i18n.language !== "ru"
        ) {
            i18n.changeLanguage("en");
        }
    }, [i18n]);

    useEffect(() => {
        const video = document.querySelector("video");
        const audio = document.querySelector("audio");

        if (audio && audio.duration > 0 && !audio.paused && audioPlaying) {
            if (video) {
                video.muted = true;
                setHasBeenMuted(true);
            }
        } else if (hasBeenMuted) {
            if (video && !audioPlaying) {
                video.muted = false;
                video && video.load();
                video && video.play();
            }
        }
    }, [audioPlaying, hasBeenMuted]);

    const showImprint = () => {
        setImprint(true);
    };

    const toggleAudio = () => {
        if (audioPlaying) {
            const audio = document.querySelector("audio");
            const video = document.querySelector("video");
            audio && audio.pause();
            if (audio && audio.currentTime) {
                audio.currentTime = 0;
            }

            setAudioPlaying(false);

            if (video) {
                video.muted = false;
                video && video.load();
                video && video.play();
                console.log("video.muted", video.muted);
            }
        } else {
            const audio = document.querySelector("audio");
            const video = document.querySelector("video");
            audio && audio.load();
            audio && audio.play();

            console.log(audio);

            setAudioPlaying(true);

            if (video) {
                video.muted = true;
                video && video.load();
                video && video.play();
                console.log("video.muted", video.muted);
            }
        }
    };

    //figure out language
    //change audio url accordingly
    // onPlay: --> play audio

    useEffect(() => {
        let isCancelled = false;
        setTimeout(() => {
            setLoading(false);
            console.log('loading false')
        }, 1000);

        return () => {
            isCancelled = true;
        };
    }, []);

    useEffect(() => {
        imprintDiv && imprintDiv.current && imprintDiv.current.scrollIntoView();
    }, [imprint]);

    return (
        <>
            <div className="App">
                {loading && <section className="white"></section>}

                <section className="hero" id="video">
                    <video autoPlay playsInline loop data-autoplay="" muted>
                        <source src="video_project.mp4" type="video/mp4" />
                    </video>
                    <nav>
                        <button>
                            <a className="nav" href="#video">
                                {t("VIDEO")}
                            </a>
                        </button>{" "}
                        <button>
                            <a className="nav" href="#manual">
                                {t("MANUAL")}
                            </a>
                        </button>
                        <button>
                            <a className="nav" href="#map">
                                {t("MAP")}
                            </a>
                        </button>{" "}
                        <button>
                            {" "}
                            <a className="nav" href="#links">
                                {t("LINKS")}
                            </a>
                        </button>
                        <button>
                            <a className="nav" href="#about">
                                {t("ABOUT")}
                            </a>
                        </button>
                        <div>
                            <button
                                style={{ fontWeight: i18n.language === "de" ? "bold" : "normal" }}
                                className="language"
                                onClick={() => changeLanguage("de")}
                            >
                                DE
                            </button>
                            |
                            <button
                                style={{ fontWeight: i18n.language === "en" ? "bold" : "normal" }}
                                className="language"
                                onClick={() => changeLanguage("en")}
                            >
                                EN
                            </button>
                            |
                            <button
                                style={{
                                    fontWeight: i18n.language === "ru" ? "bold" : "normal",
                                }}
                                className="language"
                                onClick={() => changeLanguage("ru")}
                            >
                                RU{" "}
                            </button>
                        </div>
                    </nav>
                    <img className="title" src="project_logo.svg" alt="logo" />
                </section><section className="breathingToGo" id="manual">
                    <h1 className="breathingGoTitle"> {t("/// BREATHING TO GO")} </h1>
                    <ul className="breathingGoList">
                        <li>
                            1.&nbsp;
                            {t(
                                "Call someone you care for, someone who cannot be with you at this moment."
                            )}
                        </li>
                        <li>2. {t("Both look for a quiet and comfy place to sit.")}</li>
                        {i18n.language === "en" && (
                            <li>
                                3.&nbsp;
                                <a className="guidelines-underline" href="#map">
                                    This map
                                </a>{" "}
                                provides you with some peaceful spots around Neukölln.
                            </li>
                        )}

                        {i18n.language === "de" && (
                            <li>
                                3.&nbsp;Ideen für ruhige Orte in und um Neukölln findest Du auf{" "}
                                <a className="guidelines-underline" href="#map">
                                    dieser Karte.
                                </a>
                            </li>
                        )}

                        {i18n.language === "ru" && (
                            <li>
                                3.&nbsp;На этой {""}
                                <a className="guidelines-underline" href="#map">
                                    карте
                                </a> {""}
                                вы можете найти тихие и спокойные места в Новосибирске.
                            </li>
                        )}

                        {i18n.language === "en" && (
                            <li>
                                4.&nbsp;Start breathing in and out at the same time. You can use{" "}
                                <a className="guidelines-underline" href="#video">
                                    this video
                                </a>{" "}
                                to set the pace.
                            </li>
                        )}

                        {i18n.language === "de" && (
                            <li>
                                4.&nbsp;Atmet gleichzeitig ruhig ein und aus. Ihr könnt{" "}
                                <a className="guidelines-underline" href="#video">
                                    {" "}
                                    dieses Video
                                </a>{" "}
                                als Taktgeber nutzen.
                            </li>
                        )}

                        {i18n.language === "ru" && (
                            <li>
                                4.&nbsp;Начните одновременно вдыхать и выдыхать. Чтобы задать
                                ритм, вы можете воспользоваться этим&nbsp;<a className="guidelines-underline" href="#video">видео.
                                </a>
                            </li>
                        )}

                        <li>
                            5.&nbsp;
                            {t(
                                "Try to get into the same rhythm by focusing on each other’s breathing."
                            )}
                        </li>
                        <li>
                            6.&nbsp;
                            {t(
                                "Notice how you get calmer and closer to one another with each breath."
                            )}
                        </li>
                        <li>
                            7.&nbsp;
                            {t("Before hanging up, show your appreciation for each other.")}
                        </li>
                    </ul>

                    <div className="audioContainer">
                        <span
                            className={`tooltipText ${audioPlaying
                                ? "tooltipInvisible"
                                : tooltipVisible
                                    ? "tooltipVisible"
                                    : "tooltipInvisible"}`}
                        >
                            {t("Let us read the manual to you!")}
                        </span>
                        <img
                            onMouseOver={() => setTooltipVisible(true)}
                            onMouseOut={() => setTooltipVisible(false)}
                            onClick={toggleAudio}
                            className="audio_icon"
                            src={audioPlaying ? "pause_audio.png" : "play.png"}
                            alt="audio play" />
                    </div>

                    <audio preload="auto" onEnded={() => setAudioPlaying(false)}>
                        <source
                            src={i18n.language === "en" ? "audio_en.mp3" : i18n.language === "de" ? "audio_de.mp3" : "audio_ru.mp3"}
                            type="audio/mp3" />
                        Your browser does not support the audio tag.
                    </audio>
                </section><section className="map" id="map">
                    <h1 className="mapTitle">{t("/// PLACES TO BREATHE")}</h1>
                    {i18n.language === "ru" && <RUMap />}
                    {i18n.language === "en" && <NKMap />}
                    {i18n.language === "de" && <NKMap />}

                </section><section className="ressources" id="links">
                    <h1 className="breathingGoTitle">{t("/// HELPFUL & MINDFUL LINKS")}</h1>

                    <div className="ressources-container">
                        {i18n.language === "ru" && (
                            <>
                                <span className="ressource-title">
                                    &nbsp;//&nbsp;ТЕЛЕФОНЫ ДОВЕРИЯ&nbsp;//&nbsp;
                                </span>
                                <a
                                    className="ressource-link"
                                    href="http://www.ratepp.ru/load/51"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Телефон доверия для детей и родителей
                                </a>
                                , &nbsp;
                                <a
                                    className="ressource-link"
                                    href="http://www.ratepp.ru/load/51"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Телефон доверия г.Бердска
                                </a>
                                , &nbsp;
                                <a
                                    className="ressource-link"
                                    href="http://www.ratepp.ru/load/51"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    телефоны доверия психологических центров
                                </a>
                                <span className="ressource-title">
                                    &nbsp;//&nbsp;ГРУППЫ ПОДДЕРЖКИ&nbsp;//&nbsp;
                                </span>
                                <a
                                    className="ressource-link"
                                    href="https://psy.group/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Группы психологической поддержки
                                </a>
                                , &nbsp;
                                <a
                                    className="ressource-link"
                                    href="http://appme.ru/edu/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Альянс помогающих практик ПроБОНО
                                </a>
                                <span className="ressource-title">
                                    &nbsp;//&nbsp;СООБЩЕСТВА&nbsp;//&nbsp;
                                </span>
                                <a
                                    className="ressource-link"
                                    href="https://vk.com/blablameetup"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Мультиязыковые тусовки в барах Новосибирска
                                </a>
                                , &nbsp;
                                <a
                                    className="ressource-link"
                                    href="https://vk.com/kinonakrishensk"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Кино на крыше
                                </a>
                                , &nbsp;
                                <a
                                    className="ressource-link"
                                    href="https://vk.com/nskblagost"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Клуб Благость Олега Торсунова
                                </a>
                                <span className="ressource-title">
                                    &nbsp;//&nbsp;КЛАССЫ И ХОББИ&nbsp;//&nbsp;
                                </span>
                                <a
                                    className="ressource-link"
                                    href="http://yoga-nsk.ru/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Центр йоги Намасте
                                </a>
                                , &nbsp;
                                <a
                                    className="ressource-link"
                                    href="https://sahajayoga.ru/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Центр медитации Сахаджа Йога
                                </a>
                                , &nbsp;
                                <a
                                    className="ressource-link"
                                    href="https://ipanemastudio.ru/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Студия танца Ипанема
                                </a>
                                , &nbsp;
                                <a
                                    className="ressource-link"
                                    href="https://vk.com/tribalhome"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Новосибирский Трайбл Дом
                                </a>
                            </>
                        )}
                        {i18n.language !== 'ru' && (
                            <>
                                <span className="ressource-title">
                                    &nbsp;{t("SUPPORT GROUP SEARCH")}&nbsp; &#47; &#47; &nbsp;
                                </span>
                                <a
                                    className="ressource-link"
                                    href="https://www.sekis-berlin.de/selbsthilfe/suche-nach-gruppen/?no_cache=1"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    SEKIS
                                </a>
                                &nbsp;
                                <a
                                    className="ressource-link"
                                    href="https://eigeninitiative-berlin.de/selbsthilfegruppen/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Eigeninitiative
                                </a>
                                <span className="ressource-title">
                                    &nbsp;{t(" // SUPPORT GROUPS // ")}&nbsp;
                                </span>
                                <a
                                    className="ressource-link"
                                    href="https://www.sekis-berlin.de/selbsthilfe/suche-nach-gruppen/details/8595/searchForWords?no_cache=1&cHash=ba91a516684ff47a42e868c09c7c371c"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Authentischer Kontakt & Lebensfreude
                                </a>
                                <span className="ressource-title">
                                    &nbsp;{t(" // CRISIS HOTLINES // ")}
                                </span>
                                <a
                                    className="ressource-link"
                                    href="https://www.berliner-krisendienst.de/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Krisendienst
                                </a>
                                &nbsp;
                                <a
                                    className="ressource-link"
                                    href="https://telefonseelsorge-berlin.de/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Telefonseelsorge
                                </a>
                                &nbsp;
                                <a
                                    className="ressource-link"
                                    href="https://www.notfallseelsorge-berlin.de/corona-seelsorge"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Corona-Seelsorgetelefon
                                </a>
                                &nbsp;
                                <a
                                    className="ressource-link"
                                    href="https://ktsbb.de/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Kirchliche Telefonseelsorge
                                </a>
                                &nbsp;
                                <a
                                    className="ressource-link"
                                    href="https://www.mutes.de/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Muslimisches Seelsorgetelefon
                                </a>
                                &nbsp;
                                <a
                                    className="ressource-link"
                                    href="https://krisenchat.de/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Krisenchat
                                </a>
                                &nbsp;
                                <a
                                    className="ressource-link"
                                    href="https://www.silbernetz.org/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Silbernetz
                                </a>
                                &nbsp;
                                <a
                                    className="ressource-link"
                                    href="https://www.deutsche-depressionshilfe.de/start"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Depressionshilfe
                                </a>
                                <span className="ressource-title">
                                    &nbsp;{t(" // COMMUNITY // ")}
                                </span>
                                <a
                                    className="ressource-link"
                                    href="https://www.integrationsverein-impuls.de/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Interkultureller Treffpunkt
                                </a>
                                &nbsp;
                                <a
                                    className="ressource-link"
                                    href="https://www.berlin.de/sen/soziales/besondere-lebenssituationen/menschen-mit-behinderung/freizeitprojekte/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Freizeitgruppen
                                </a>
                                &nbsp;
                                <a
                                    className="ressource-link"
                                    href="https://www.isp-freizeitprojekte.de/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Freizeitprojekte
                                </a>
                                <span className="ressource-title">
                                    &nbsp;{t(" // CLASSES // ")}
                                </span>
                                <a
                                    className="ressource-link"
                                    href="https://www.mbsr-verband.de/kurse/privatpersonen/suchergebnis/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    MBSR
                                </a>
                                &nbsp;
                                <a
                                    className="ressource-link"
                                    href="https://berlin.shambhala.info/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Shambhala Meditationszentrum
                                </a>
                                &nbsp;
                                <a
                                    className="ressource-link"
                                    href="https://www.mindfulnessberlin.de/kursangebot/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Mindfulness Berlin
                                </a>
                                <span className="ressource-title">
                                    &nbsp;{t(" // GET ACTIVE // ")}&nbsp;
                                </span>
                                <a
                                    className="ressource-link"
                                    href="https://www.buendnis-neukoelln.de/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Bündnis Neukölln e.V.
                                </a>
                                &nbsp;
                                <a
                                    className="ressource-link"
                                    href="https://www.adunare.de/nean/ueber-uns/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Netzwerk Ehrenamt Neukölln
                                </a>
                                &nbsp;
                                <a
                                    className="ressource-link"
                                    href="https://berlin.nabu.de/wir-ueber-uns/geschaeftsstelle/jobboerse/ehrenamt/index.html"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    NABU Berlin
                                </a>
                                &nbsp;
                                <a
                                    className="ressource-link"
                                    href="https://www.nez-neukoelln.de/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Neuköllner EngagementZentrum
                                </a>
                            </>
                        )}
                        <img
                            className="ressources_cutOut"
                            src="ressources_cutout.png"
                            alt="cutout" />
                    </div>
                </section><section className="about" id="about">
                    <div className="about-container">
                        <h1 className="about-title">
                            &nbsp;&#47;&#47;&#47;&nbsp;{t("THE PROJECT")}
                        </h1>
                        <div>
                            {i18n.language === "en" && (
                                <p id="about_first">
                                    Breathing can be a shared performative experience. With our
                                    breath, we communicate our emotions and current state of mind to
                                    others. We breathe faster in panic, yawn when we are tired, and
                                    hold our breath in shock. <em>ein/aus</em> is a temporary
                                    concept that brings to mind the idea of a crisis phone. You can
                                    reach us via the hotline{" "}
                                    <a className="textUnderline" href="tel:00491778670340">
                                        +491778670340
                                    </a>{" "}
                                    to breathe together with us. On the website, we collected ideas
                                    for you to find peace for yourself and with others through your
                                    breathing. In a time marked by inner tensions and social
                                    isolation, a safe and stable space is esta- blished on the phone
                                    and online.{" "}
                                </p>
                            )}

                            {i18n.language === "de" && (
                                <p id="about_first">
                                    Atmen kann ein gemeinsamer, performativer Akt sein. Zudem ist es
                                    immer auch eine Botschaft an unser Gegenüber, bringt Emotionen
                                    und Zustände zum Ausdruck. Wir atmen schneller in Panik, gähnen
                                    bei Müdigkeit und halten im Schockzustand die Luft an. Daran
                                    angelehnt erinnert <em>ein/aus</em> als temporäres Konzept an
                                    ein Krisentelefon. Über die Hotline{" "}
                                    <a className="textUnderline" href="tel:00491778670340">
                                        +491778670340
                                    </a>{" "}
                                    atmen wir ge- meinsam mit euch, auf der Website findet ihr Ideen
                                    und Hilfestellungen – um für euch selbst oder im Austausch mit
                                    anderen über die Atmung zur Ruhe zu kommen. In einer Zeit, die
                                    von Anspannung und erzwungener Isolation geprägt ist, kann so
                                    Raum für Nähe und Sicherheit entstehen.
                                </p>
                            )}

                            {i18n.language === "ru" && (
                                <p id="about_first">
                                    Дыхание может быть объединяющим перформативным опытом. При помощи
                                    дыхания мы передаём другим людям свои эмоции, сообщаем о своём
                                    состоянии. Мы дышим быстрее в состоянии паники, зеваем от
                                    усталости и задерживаем дыхание от шока. ein/aus это временный
                                    концепт, призванный донести до людей идею кризисной телефонной
                                    службы. Вы можете позвонить нам по горячей линии:{" "}
                                    <a className="textUnderline" href="tel:00491778670340">
                                        +79231454173
                                    </a>{" "}
                                    чтобы подышать вместе с нами. На нашем сайте мы собрали для вас
                                    идеи, как войти в состояние покоя самостоятельно или вместе с
                                    другими через процесс дыхания. В наше время, время внутреннего
                                    напряжения и социальной изоляции мы создаём пространство
                                    безопасности и стабильности по телефону и онлайн.{" "}
                                </p>
                            )}

                            {i18n.language === "en" && (
                                <p>
                                    {" "}
                                    For more than fourteen months now our lives have been on hold –
                                    locked down in a global crisis that has not only brought
                                    insecurity, instability and existential fears but also a state
                                    of constant physical isolation among us.
                                </p>
                            )}

                            {i18n.language === "de" && (
                                <p>
                                    Seit nunmehr vierzehn Monaten befinden wir uns in einer
                                    gesellschaftlichen Ausnahmesituation, die nicht nur Un-
                                    sicherheit, Instabilität und existenzielle Ängste mit sich
                                    gebracht hat, sondern uns auch rein körperlich anhaltend
                                    isoliert. Genau wie das kulturelle Leben ist auch das soziale
                                    fast zum Stillstand gekommen.
                                </p>
                            )}

                            {i18n.language === "ru" && (
                                <p>
                                    На настоящий момент уже более четырнадцати месяцев наши жизни
                                    поставлены на паузу: мы находимся на карантине в мировом
                                    кризисе, что вызывает в нас не только чувство опасности,
                                    нестабильности и страха за свою жизнь, но и приводит к состоянию
                                    непрерывной физической изоляции друг от друга.
                                </p>
                            )}
                        </div>
                        <div>
                            {i18n.language === "en" && (
                                <p>
                                    {" "}
                                    Just like cultural life, our social ones have been paused. Yet,
                                    instead of quiet and inner peace, we feel constant pressure,
                                    stress and loneliness.
                                </p>
                            )}

                            {i18n.language === "de" && (
                                <p>
                                    Was Achtsamkeitsübungen, Strategien zur Auflösung psychischer
                                    Angstzustände, Yoga- oder Entspannungs- techniken eint, ist ein
                                    gemeinsamer Ausgangspunkt: die eigene Atmung. Das bewusste Atmen
                                    ist immer auch eine bewusste Auseinandersetzung mit dem eigenen
                                    Körper, den eigenen Bedürfnissen, den eigenen Emotionen. Indem
                                    wir uns gezielt auf unsere Atmung konzentrieren und sie steuern,
                                    widmen wir uns dem eigenen Körper und schenken dem unglaublichen
                                    Mechanismus, der uns am Leben hält und normalerweise im
                                    Hintergrund abläuft, Aufmerksamkeit. Über diese Wertschätzung
                                    des Atmens kommen wir uns selbst näher und können uns auch
                                    anderen nähern.
                                </p>
                            )}

                            {i18n.language === "ru" && (
                                <p>
                                    {" "}
                                    На паузу были поставлены как культурная жизнь, так и наши
                                    социальные связи. Однако вместо внутреннего мира и покоя мы
                                    ощущаем постоянное давление, стресс и одиночество.
                                </p>
                            )}

                            {i18n.language === "en" && (
                                <p>
                                    {" "}
                                    The one thing that various exercises of mindfulness, strategies
                                    for resolving psychological anxiety, yoga or relaxation
                                    techniques share is the point from which they unfold: our
                                    breathing. In controlling and closely observing our breathing,
                                    we are able to engage with our body, our personal needs and
                                    emotions. We show appreciation for the incredible mechanism that
                                    keeps us alive – one that normally runs in the background by
                                    default. By actively appreciating our own breathing, we can come
                                    closer to ourselves and others.
                                </p>
                            )}

                            {i18n.language === "ru" && (
                                <p>
                                    {" "}
                                    То общее, что имеют разнообразные практики развития
                                    осознанности, техники снятия психического напряжения, йога и
                                    техники релаксации, это точка, с которой они начинаются: наше
                                    дыхание. Контролируя и внимательно наблюдая за процессом
                                    дыхания, мы можем войти в более тесный контакт со своим телом,
                                    потребностями и эмоциями. Мы выражаем признательность этому
                                    удивительному механизму, который поддерживает в нас жизнь и
                                    который в норме происходит без нашего внимания. Учась ценить
                                    своё дыхание мы становимся ближе к себе и к другим.
                                </p>
                            )}

                            {i18n.language === "de" && (
                                <p>
                                    Über die Dauer des diesjährigen Festivals wollen wir zumindest
                                    einen Teil des Vertrauens und der im Laufe der Pandemie verloren
                                    gegangenen Kontrolle zurückfordern, einen Ort der Sicherheit und
                                    des Bewusstmachens schaffen. Statt Atmung allein als mentale
                                    oder spirituelle Praxis zu betrachten, liegt der Fokus dabei auf
                                    der Gemeinsamkeit. Angelehnt ist die temporäre Arbeit{" "}
                                    <em>ein/aus</em> an das Konzept eines Krisentelefons. Ausgehend
                                    von einer telefonischen Hotline ist sie ein Aufruf zu Nähe in
                                    einer Zeit physischer Distanz: Indem wir bewusst und gemeinsam
                                    mit Anrufenden atmen, besinnen wir uns auf eine
                                    Kommunikationsform, die rein nonverbal stattfindet.
                                </p>
                            )}

                            {i18n.language === "en" && (
                                <p>
                                    {" "}
                                    For this year's festival, we want to reclaim at least some of
                                    the inner trust and sense of control lost during the pandemic.
                                    We want to create a safe space for stability and mindfulness.
                                    Rather than looking at breathing solely as a mental or spiritual
                                    practice, the focus on the things we share. The temporary work{" "}
                                    <em>ein/aus</em> brings to mind a crisis phone. Based on this
                                    concept, it is a call for intimacy in a time of physical
                                    distance: We breathe together consciously and thus learn to
                                    appreciate a purely non-verbal mode of communication.
                                </p>
                            )}

                            {i18n.language === "ru" && (
                                <p>
                                    На фестивале в этом году мы хотим вернуть себе хотя бы часть
                                    внутреннего доверия и чувства контроля, которое забрала у нас
                                    пандемии. Мы хотим создать пространство безопасности,
                                    стабильности и осознанности. Мы рассматриваем дыхание не столько
                                    как духовную практику или работу с сознанием, сколько
                                    сосредотачиваемся на том, чем что нас объединяет. Временный
                                    проект <em>ein/aus</em> напоминает телефон кризисной службы.
                                    Основанный на этой концепции, он является призывом к близости в
                                    условиях физического отдаления: мы осознанно дышим вместе и
                                    таким образом учимся ценить абсолютно невербальный способ
                                    общения.
                                </p>
                            )}
                        </div>

                        <div>
                            {i18n.language === "en" && (
                                <p>
                                    {" "}
                                    <em>ein/aus</em> places the festival theme of air within a
                                    contemporary context – as an approach that sees breath as a
                                    life-bringing and imminently threatened appreciation of our
                                    body. At the same time, it can be used as a practical guide that
                                    brings together people all over Berlin, resolves the current
                                    state of isolation, and establishes a short-term network far
                                    beyond the cultural sphere. As the mobile number will be
                                    available for the festival weekend only, the hotline remains
                                    immaterial and yet is deeply connected to Neukölln.{" "}
                                </p>
                            )}

                            {i18n.language === "de" && (
                                <p>
                                    Nicht nur setzt sich <em>ein/aus</em> somit in den gegenwärtigen
                                    Kontext eingebunden mit dem Thema Luft auseinander – mit dem
                                    Atem als lebensbringende und akut bedrohte Wertschätzung des
                                    eigenen Körpers – sondern die Arbeit ist auch ganz konkret eine
                                    nutzbare Hilfestellung, die Menschen in ganz Berlin
                                    zusammenbringt, Isolationen auflöst und kurzfristig ein Netzwerk
                                    schafft, das über den kulturellen Bereich hinausgeht. Die
                                    Mobilnummer wird nur über das Festivalwochenende erreichbar
                                    sein, die Hotline bleibt somit immateriell und ist doch
                                    verortet. Unabhängig davon, ob der Lockdown weiter anhält oder
                                    erneute Einschränkungen folgen, kann die Arbeit interaktiv und
                                    gemeinsam erlebt werden.
                                </p>
                            )}

                            {i18n.language === "ru" && (
                                <p>
                                    <em>ein/aus</em> раскрывает тему фестиваля Воздух в рамках
                                    современного контекста. Это подход, рассматривающий дыхание как
                                    животворящую ценность тела, которая подвержена неизбежным
                                    угрозам. В то же время данный проект можно использовать как
                                    практическое руководство, объединяющее людей по всему
                                    Новосибирску или даже России, призванное избавить от чувства
                                    изоляции и установить кратковременные связи далеко за пределами
                                    сферы культуры. Поскольку мобильный номер доступен только в
                                    фестивальные выходные, идея горячей линии остаётся в своей
                                    незримой форме быть связанной с местами Новосибирска и с
                                    фестивалем.
                                </p>
                            )}

                            {i18n.language === "en" && (
                                <p>
                                    {" "}
                                    <em>ein/aus</em> is a temporary and interactive intervention by{" "}
                                    <a
                                        href="https://janoschbecker.jimdofree.com/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="textUnderline"
                                    >
                                        Janosch Becker
                                    </a>{" "}
                                    and{" "}
                                    <a
                                        href="https://hannakomornitzyk.com/"
                                        className="textUnderline"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Hanna Komornitzyk
                                    </a>
                                    &nbsp; developed specifically for the festival 48 Stunden
                                    Neukölln.{" "}
                                </p>
                            )}

                            {i18n.language === "de" && (
                                <p>
                                    <em>ein/aus</em> wurde als temporäre und interaktive
                                    Intervention von{" "}
                                    <a
                                        href="https://janoschbecker.jimdofree.com/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="textUnderline"
                                    >
                                        Janosch Becker
                                    </a>{" "}
                                    und{" "}
                                    <a
                                        href="https://hannakomornitzyk.com/"
                                        className="textUnderline"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Hanna Komornitzyk
                                    </a>{" "}
                                    speziell für das Festival 48 Stunden Neukölln entwickelt.
                                </p>
                            )}

                            {i18n.language === "ru" && (
                                <p>
                                    {" "}
                                    <em>ein/aus</em> это временный интерактивный проект{" "}
                                    <a
                                        href="https://janoschbecker.jimdofree.com/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="textUnderline"
                                    >
                                        Яноша Беккера
                                    </a>{" "}
                                    и{" "}
                                    <a
                                        href="https://hannakomornitzyk.com/"
                                        className="textUnderline"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Ханны Коморницик
                                    </a>
                                    &nbsp; созданный специально для фестиваля 48 часов Нойкёльн. На
                                    фестивале 48 часов Новосибирск проект проведёт <a className="textUnderline" href="https://48-hours-nsk.com/participants/mirsayafova-mariya" target="_blank" rel="noopener noreferrer" >Мария Мирсаяфова </a>.
                                </p>
                            )}
                        </div>

                        <div className="festivalLogo">
                            {i18n.language === "ru" && (
                                <a
                                    href="https://48-hours-nsk.com/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img src="logo_ru.png" alt="logo" />
                                </a>
                            )}
                            <a
                                href="https://48-stunden-neukoelln.de/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src="festival_logo.png" alt="logo" />
                            </a>
                        </div>
                    </div>
                </section>

                <footer>
                    {t("48 Stunden Neukölln 2021")} <br />
                    {t("© Janosch Becker & Hanna Komornitzyk")} <br />
                    {t("Web Development by Audrey Kadjar")} <br />
                    <span className="imprint" onClick={showImprint}>
                        {" "}
                        {t("Imprint")}
                    </span>
                </footer>

                {imprint && (
                    <section className="imprint">
                        <div className="impressum_text" ref={imprintDiv}>
                            <br />
                            <span className="closeImprint" onClick={() => setImprint(false)}>
                                {" "}
                                {t("CLOSE IMPRINT")} [X]
                            </span>{" "}
                            <br />
                            <br />
                            <b>Impressum </b>
                            <br />
                            <p>
                                Janosch Becker & Hanna Komornitzyk <br />
                                Koloniestraße 126A
                                <br />
                                13359 Berlin
                            </p>
                            <p>
                                {t("Phone")}: +49 (0) 177 8670340 <br />
                                E-Mail: einauseinauseinaus@gmail.com
                            </p>
                            <p>
                                {" "}
                                <b>Hinweis gemäß Online-Streitbeilegungs-Verordnung </b>
                                <br />
                                <br />
                                Nach geltendem Recht sind wir verpflichtet, Verbraucher auf die
                                Existenz der Europäischen Online-Streitbeilegungs-Plattform
                                hinzuweisen, die für die Beilegung von Streitigkeiten genutzt
                                werden kann, ohne dass ein Gericht eingeschaltet werden muss. Für
                                die Einrichtung der Plattform ist die Europäische Kommission
                                zuständig. Die Europäische Online-Streitbeilegungs-Plattform ist
                                hier zu finden: http://ec.europa.eu/odr. Unsere E-Mail lautet:
                                einauseinauseinaus@gmail.com. Wir weisen aber darauf hin, dass wir
                                nicht bereit sind, uns am Streitbeilegungsverfahren im Rahmen der
                                Europäischen Online-Streitbeilegungs-Plattform zu beteiligen.
                                Nutzen Sie zur Kontaktaufnahme bitte unsere obige E-Mail und
                                Telefonnummer.
                                <br />
                                <br />
                                <b>Disclaimer – rechtliche Hinweise </b>
                            </p>
                            <p>
                                § 1 Warnhinweis zu Inhalten
                                <br />
                                Die kostenlosen und frei zugänglichen Inhalte dieser Website
                                wurden mit größtmöglicher Sorgfalt erstellt. Der Anbieter dieser
                                Webseite übernimmt jedoch keine Gewähr für die Richtigkeit und
                                Aktualität der bereitgestellten kostenlosen und frei zugänglichen
                                journalistischen Ratgeber und Nachrichten. Namentlich
                                gekennzeichnete Beiträge geben die Meinung des jeweiligen Autors
                                und nicht immer die Meinung des Anbieters wieder. Allein durch den
                                Aufruf der kostenlosen und frei zugänglichen Inhalte kommt
                                keinerlei Vertragsverhältnis zwischen dem Nutzer und dem Anbieter
                                zustande, insoweit fehlt es am Rechtsbindungswillen des Anbieters.
                            </p>
                            <p>
                                § 2 Externe Links
                                <br />
                                Diese Website enthält Verknüpfungen zu Websites Dritter ("externe
                                Links"). Diese Websites unterliegen der Haftung der jeweiligen
                                Betreiber. Der Anbieter hat bei der erstmaligen Verknüpfung der
                                externen Links die fremden Inhalte daraufhin überprüft, ob etwaige
                                Rechtsverstöße bestehen. Zu dem Zeitpunkt waren keine
                                Rechtsverstöße ersichtlich. Der Anbieter hat keinerlei Einfluss
                                auf die aktuelle und zukünftige Gestaltung und auf die Inhalte der
                                verknüpften Seiten. Das Setzen von externen Links bedeutet nicht,
                                dass sich der Anbieter die hinter dem Verweis oder Link liegenden
                                Inhalte zu Eigen macht. Eine ständige Kontrolle der externen Links
                                ist für den Anbieter ohne konkrete Hinweise auf Rechtsverstöße
                                nicht zumutbar. Bei Kenntnis von Rechtsverstößen werden jedoch
                                derartige externe Links unverzüglich gelöscht.
                            </p>
                            <p>
                                § 3 Urheber- und Leistungsschutzrechte
                                <br />
                                Die auf dieser Website veröffentlichten Inhalte unterliegen dem
                                deutschen Urheber- und Leistungsschutzrecht. Jede vom deutschen
                                Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung
                                bedarf der vorherigen schriftlichen Zustimmung des Anbieters oder
                                jeweiligen Rechteinhabers. Dies gilt insbesondere für
                                Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung,
                                Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder
                                anderen elektronischen Medien und Systemen. Inhalte und Rechte
                                Dritter sind dabei als solche gekennzeichnet. Die unerlaubte
                                Vervielfältigung oder Weitergabe einzelner Inhalte oder kompletter
                                Seiten ist nicht gestattet und strafbar. Lediglich die Herstellung
                                von Kopien und Downloads für den persönlichen, privaten und nicht
                                kommerziellen Gebrauch ist erlaubt. Die Darstellung dieser Website
                                in fremden Frames ist nur mit schriftlicher Erlaubnis zulässig.
                            </p>
                            <p>
                                § 4 Besondere Nutzungsbedingungen
                                <br />
                                Soweit besondere Bedingungen für einzelne Nutzungen dieser Website
                                von den vorgenannten Paragraphen abweichen, wird an entsprechender
                                Stelle ausdrücklich darauf hingewiesen. In diesem Falle gelten im
                                jeweiligen Einzelfall die besonderen Nutzungsbedingungen.
                            </p>
                        </div>
                    </section>
                )}
            </div>
        </>
    );
}

export default Info;

//footer: credits / festival
//imprint
