import L, { LatLngExpression } from "leaflet";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";

interface BreathingPlace {
  name: string;
  lat: number;
  lon: number;
}

// const MyMarker = (props) => {
//   const initMarker = (ref) => {
//     if (ref) {
//       ref.leafletElement.openPopup();
//     }
//   };

//   return <Marker ref={initMarker} {...props} />;
// };

const NKMap = () => {
  //[52.47952087866551, 13.438199379459157]
  //52.474564125891064, 13.428406475330465
  //52.48238029072737, 13.43460773940431
  //
  const [mapCoord] = useState<LatLngExpression>([
    52.46716634220548, 13.443651249772836,
  ]);
  const zoom: number = 13;
  const { t } = useTranslation();

  const breatingPlaces: BreathingPlace[] = [
    {
      name: t("Central Path at Tempelhofer Feld"),
      lat: 52.47337414410845,
      lon: 13.408469517659645,
    },
    {
      name: t("Meadow at Körnerpark"),
      lat: 52.47145261841009,
      lon: 13.43789128102184,
    },
    {
      name: t("St. Thomas / St. Michael Churchyard"),
      lat: 52.47364215382663,
      lon: 13.431636770228616,
    },
    {
      name: t("Benches at Comenius Gardens"),
      lat: 52.475195289309916,
      lon: 13.443575253272995,
    },
    {
      name: t("Von-der-Schulenburg-Park"),
      lat: 52.467417,
      lon: 13.471000,
    },
    {
      name: t("St. Jakobi Churchyard"),
      lat: 52.484417,
      lon: 13.425306,
    },
    {
      name: t("Border Triangle at Landwehrkanal"),
      lat: 52.489691969434915,
      lon: 13.438176321425077,
    },
    {
      name: t("Rixdorfer Heights at Hasenheide"),
      lat: 52.48475,
      lon: 13.412361,
    },
    {
      name: t("Plänterwald around Spreepark"),
      lat: 52.484388851647545,
      lon: 13.48700320830404,
    },
    {
      name: t("Steps of the Soviet War Memorial"),
      lat: 52.48673185215751,
      lon: 13.47112314084556,
    },
    {
      name: t("Jetty on Insel der Jugend"),
      lat: 52.487779430139526,
      lon: 13.479291576216418,
    },
    {
      name: t("Tempelhofer Park Cemetery"),
      lat: 52.453954314178254,
      lon: 13.403666453736932,
    },
  ];

  // const openPopup = (marker) => {
  //   console.log(marker.options.title === 'Central Path at Tempelhofer Feld')
  //   if (marker && marker.leafletElement) {
  //     window.setTimeout(() => {
  //       marker.leafletElement.openPopup()
  //     })
  //   }
  // }

  //setMapCoord([item.lat, item.lon])
  // ref={openPopup}

  return (
    <>
      <div id="mapid">
        <MapContainer
          center={mapCoord}
          zoom={zoom}
          scrollWheelZoom={false}
          click={() => console.log("pop up open")}
        >
          <TileLayer
            attribution="&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {breatingPlaces.map((item, index) => (
            <Marker
              icon={L.divIcon({
                className: "icon",
                iconSize: [20, 20],
                iconAnchor: [0, 0],
                popupAnchor: [10, 0],
                html: (index + 1).toString()
              })}
              key={index}
              position={[item.lat, item.lon]}
              title={`${item.name}`}
              pane="markerPane"
            >
              <Popup>
                <strong>{item.name}</strong>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </div>
      <div className="mapLinks">
        <div className="mapRef-container">
          <a
            className="mapRef"
            href="https://www.google.com/maps/place/52%C2%B028'24.0%22N+13%C2%B024'30.5%22E/@52.4733333,13.407925,110m/data=!3m2!1e3!4b1!4m5!3m4!1s0x0:0x0!8m2!3d52.4733238!4d13.4084801"
            target="_blank"
            rel="noopener noreferrer"
          >
            1.&nbsp;<span className="mapUnderline">{t("Central Path at Tempelhofer Feld")}</span>
          </a>
          <a
            className="mapRef"
            href="https://www.google.com/maps/place/52%C2%B028'17.0%22N+13%C2%B026'16.4%22E/@52.4713889,13.4367946,221m/data=!3m2!1e3!4b1!4m5!3m4!1s0x0:0x0!8m2!3d52.4713972!4d13.4378747"
            target="_blank"
            rel="noopener noreferrer"
          >
            2.&nbsp;<span className="mapUnderline">{t("Meadow at Körnerpark")}</span>
          </a>
          <a
            className="mapRef"
            href="https://www.google.de/maps/place/52%C2%B028%2724.7%22N+13%C2%B025%2753.9%22E/@52.473526,13.4294393,441m/data=!3m2!1e3!4b1!4m5!3m4!1s0x0:0x0!8m2!3d52.4735228!4d13.431628"
            target="_blank"
            rel="noopener noreferrer"
          >
            3.&nbsp;<span className="mapUnderline">{t("St. Thomas / St. Michael Churchyard")}</span>
          </a>
        </div>

        <div className="mapRef-container">
          <a
            className="mapRef"
            href="https://www.google.com/maps/place/52%C2%B028%2730.6%22N+13%C2%B026%2736.9%22E/@52.4751788,13.4430372,110m/data=!3m2!1e3!4b1!4m5!3m4!1s0x0:0x0!8m2!3d52.475178!4d13.4435844"
            target="_blank"
            rel="noopener noreferrer"
          >
            4.&nbsp;<span className="mapUnderline">{t("Benches at Comenius Gardens")}</span>
          </a>
          <a
            className="mapRef"
            href="https://www.google.com/maps/place/52°28%2702.7%22N+13°28%2715.6%22E/@52.4674246,13.4688081,483m/data=!3m2!1e3!4b1!4m5!3m4!1s0x0:0x0!8m2!3d52.4674246!4d13.4709968"
            target="_blank"
            rel="noopener noreferrer"
          >
            5.&nbsp;<span className="mapUnderline">{t("Von-der-Schulenburg-Park")}</span>
          </a>
          <a
            className="mapRef"
            href="https://www.google.com/maps/place/52%C2%B029%2703.9%22N+13%C2%B025%2731.1%22E/@52.4844294,13.4241967,220m/data=!3m2!1e3!4b1!4m5!3m4!1s0x0:0x0!8m2!3d52.4844278!4d13.425291"
            target="_blank"
            rel="noopener noreferrer"
          >
            6.&nbsp;<span className="mapUnderline">{t("St. Jakobi Churchyard")}</span>
          </a>
        </div>

        <div className="mapRef-container">
          <a
            className="mapRef"
            href="http://google.com/maps/place/52%C2%B029'03.9%22N+13%C2%B025'31.1%22E/@52.4897279,13.438466,441m/data=!3m1!1e3!4m5!3m4!1s0x0:0x0!8m2!3d52.4844278!4d13.425291"
            target="_blank"
            rel="noopener noreferrer"
          >
            7.&nbsp;<span className="mapUnderline">{t("Border Triangle at Landwehrkanal")}</span>
          </a>
          <a
            className="mapRef"
            href="https://www.google.com/maps/place/52%C2%B029'05.1%22N+13%C2%B024'44.5%22E/@52.48475,13.4106312,441m/data=!3m2!1e3!4b1!4m5!3m4!1s0x0:0x0!8m2!3d52.4847526!4d13.4123701"
            target="_blank"
            rel="noopener noreferrer"
          >
            8.&nbsp;<span className="mapUnderline">{t("Rixdorfer Heights at Hasenheide")}</span>
          </a>
          <a
            className="mapRef"
            href="https://www.google.com/maps/place/52%C2%B029%2704.0%22N+13%C2%B029%2713.8%22E/@52.4844482,13.4849713,441m/data=!3m2!1e3!4b1!4m5!3m4!1s0x0:0x0!8m2!3d52.484445!4d13.48716"
            target="_blank"
            rel="noopener noreferrer"
          >
            9.&nbsp;<span className="mapUnderline">{t("Plänterwald around Spreepark")}</span>
          </a>
        </div>

        <div className="mapRef-container">
          <a
            className="mapRef"
            href="https://www.google.com/maps/place/52%C2%B029%2710.6%22N+13%C2%B028%2718.1%22E/@52.486271,13.469511,441m/data=!3m2!1e3!4b1!4m5!3m4!1s0x0:0x0!8m2!3d52.4862678!4d13.4716997"
            target="_blank"
            rel="noopener noreferrer"
          >
            10.&nbsp;<span className="mapUnderline">{t("Steps of the Soviet War Memorial")}</span>
          </a>
          <a
            className="mapRef"
            href="https://www.google.com/maps/place/52%C2%B029'16.0%22N+13%C2%B028'45.1%22E/@52.4877778,13.4783313,220m/data=!3m2!1e3!4b1!4m5!3m4!1s0x0:0x0!8m2!3d52.4877819!4d13.4791992"
            target="_blank"
            rel="noopener noreferrer"
          >
            11.&nbsp;<span className="mapUnderline">{t("Jetty on Insel der Jugend")}</span>
          </a>
          <a
            className="mapRef"
            href="https://www.google.com/maps/place/52%C2%B027%2713.5%22N+13%C2%B024%2715.7%22E/@52.4537554,13.403252,221m/data=!3m2!1e3!4b1!4m5!3m4!1s0x0:0x0!8m2!3d52.4537538!4d13.4043463"
            target="_blank"
            rel="noopener noreferrer"
          >
            12.&nbsp;<span className="mapUnderline">{t("Tempelhofer Park Cemetery")}</span>
          </a>
        </div>
      </div>
    </>
  );
};

export default NKMap;
