import L, { LatLngExpression } from "leaflet";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import React, { useState } from "react";

interface BreathingPlace {
  name: string;
  lat: number;
  lon: number;
}

// const MyMarker = (props) => {
//   const initMarker = (ref) => {
//     if (ref) {
//       ref.leafletElement.openPopup();
//     }
//   };

//   return <Marker ref={initMarker} {...props} />;
// };

const RUMap = () => {
  //[52.47952087866551, 13.438199379459157]
  //52.474564125891064, 13.428406475330465
  //52.48238029072737, 13.43460773940431
  //

  const [mapCoord] = useState<LatLngExpression>([
    54.817741725829805, 82.9768037113891
  ]);
 
  const zoom: number = 10;

  const breatingPlaces: BreathingPlace[] = [
    {
      name: 'Центральный сибирский ботанический сад',
      lat: 54.8208196899828,
      lon: 83.10476245492906,
    },
    {
      name: 'Дендрологический парк',
      lat: 55.06225569393329,
      lon: 82.88463288970858,
    },
    {
      name: 'ПКиО Сосновый бор',
      lat: 55.0839392433971,
      lon: 82.95922811698847,
    },
    {
      name: 'ПКиО Берёзовая роща',
      lat: 55.04669940033482,
      lon: 82.94979682953213,
    },
  ];

  // const openPopup = (marker) => {
  //   console.log(marker.options.title === 'Central Path at Tempelhofer Feld')
  //   if (marker && marker.leafletElement) {
  //     window.setTimeout(() => {
  //       marker.leafletElement.openPopup()
  //     })
  //   }
  // }

  //setMapCoord([item.lat, item.lon])
  // ref={openPopup}

  return (
    <>
      <div id="mapid">
        <MapContainer
          center={mapCoord}
          zoom={zoom}
          scrollWheelZoom={false}
          click={() => console.log("pop up open")}
        >
          <TileLayer
            attribution="&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {breatingPlaces.map((item, index) => (
            <Marker
              icon={L.divIcon({
                className: "icon",
                iconSize: [20, 20],
                iconAnchor: [0, 0],
                popupAnchor: [10, 0],
                html: (index + 1).toString()
              })}
              key={index}
              position={[item.lat, item.lon]}
              title={`${item.name}`}
              pane="markerPane"
            >
              <Popup>
                <strong>{item.name}</strong>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </div>
      <div className="mapLinks">
        <div className="mapRef-container">
          <a
            className="mapRef"
            href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x42dfc445f7c0a531:0xefc58964641ae74a?source=g.page.share"
            target="_blank"
            rel="noopener noreferrer"
          >
            1.&nbsp;<span className="mapUnderline">Центральный сибирский ботанический сад</span>
          </a>
          <a
            className="mapRef"
            href="https://goo.gl/maps/wCbBZZHS1qiBoAZ6A"
            target="_blank"
            rel="noopener noreferrer"
          >
            2.&nbsp;<span className="mapUnderline">Дендрологический парк</span>
          </a>
          <a
            className="mapRef"
            href="https://goo.gl/maps/7RqShUCe2YsgTVJJ8"
            target="_blank"
            rel="noopener noreferrer"
          >
            3.&nbsp;<span className="mapUnderline">ПКиО Сосновый бор</span>
          </a>
        </div>

        <div className="mapRef-container">
          <a
            className="mapRef"
            href="https://www.google.ru/maps/place/%D0%91%D0%B5%D1%80%D0%B5%D0%B7%D0%BE%D0%B2%D0%B0%D1%8F+%D0%A0%D0%BE%D1%89%D0%B0/@55.0466933,82.9489546,17z/data=!3m1!4b1!4m5!3m4!1s0x42dfe58d74c61959:0xcf74bb98fb8eac11!8m2!3d55.0466902!4d82.9511433?hl=ru&shorturl=1"
            target="_blank"
            rel="noopener noreferrer"
          >
            4.&nbsp;<span className="mapUnderline">ПКиО Берёзовая роща</span>
          </a>
          
          <a
            className="mapRef"
            href="https://www.google.com/maps/place/Novosibirsk"
            target="_blank"
            rel="noopener noreferrer"
          >
            5.&nbsp;<span className="mapUnderline mapRef">Любая озелененная и тихая территория неподалеку от вас</span>
          </a>

         
        </div>
      </div>
    </>
  );
};

export default RUMap;