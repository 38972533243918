let ru = {
    translations: {
      VIDEO: "ВИДЕО",
      MANUAL: "РУКОВОДСТВО",
      MAP: "КАРТА",
      LINKS: "ССЫЛКИ",
      ABOUT: "О ПРОЕКТЕ",
      "/// BREATHING TO GO": "/// КАК ДЫШАТЬ",
      "Call someone you care for, someone who cannot be with you at this moment.": "Позвоните близкому человеку, который в данный момент не может быть с вами.",
      "Both look for a quiet and comfy place to sit.": "И вы, и он найдите комфортное, спокойное место, где можно сидеть.",
      "Try to get into the same rhythm by focusing on each other’s breathing.": "Постарайтесь дышать в одном ритме, для этого фокусируйте внимание на дыхании друг друга.",
      "Notice how you get calmer and closer to one another with each breath.":"Замечайте, как с каждым вдохом вы успокаиваетесь и становитесь ближе друг к другу.",
      "Before hanging up, show your appreciation for each other.":"Перед тем, как положить трубку, поблагодарите друг друга.",
      "Let us read the manual to you!": "Позвольте нам прочитать инструкцию для Вас!",
      "/// PLACES TO BREATHE":"/// МЕСТА ДЛЯ ДЫХАНИЯ",
      "/// HELPFUL & MINDFUL LINKS": "/// ПОЛЕЗНЫЕ ССЫЛКИ",
      "SUPPORT GROUP SEARCH": "ТЕЛЕФОНЫ ДОВЕРИЯ //",
      " // SUPPORT GROUPS // ": "// ГРУППЫ ПОДДЕРЖКИ // ",
      " // CRISIS HOTLINES // ": "// СООБЩЕСТВА // ",
      " // CLASSES // ": "// КЛАССЫ И ХОББИ //",
      "THE PROJECT": "О ПРОЕКТЕ",
      "48 Stunden Neukölln 2021": "48 часов Нойкёльн / 48 часов Новосибирск 2021",
      "© Janosch Becker & Hanna Komornitzyk": "© Яноша Беккера & Ханны Коморницик",
      "Web Development by Audrey Kadjar": "Веб разработка выполнена Одри Кадьяр",
      "Imprint": "Правовая информация",
    },
  };
  
  export default ru;