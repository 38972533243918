let de = {
  translations: {
    VIDEO: "VIDEO",
    MANUAL: "ANLEITUNG",
    MAP: "KARTE",
    LINKS: "LINKS",
    ABOUT: "ABOUT",
    "/// BREATHING TO GO": "/// ATMEN TO GO",
    "Call someone you care for, someone who cannot be with you at this moment.": "Rufe eine Dir wichtige Person an, die gerade nicht bei Dir sein kann.",
    "Both look for a quiet and comfy place to sit.": "Sucht Euch einen ruhigen Ort, an dem Ihr entspannt sitzen könnt. ",
    "Try to get into the same rhythm by focusing on each other’s breathing.": "Nehmt einander beim Atmen wahr. Versucht, in den gleichen Rhythmus zu kommen.",
    "Notice how you get calmer and closer to one another with each breath.":"Spürt, wie langsam Ruhe einkehrt und Nähe entsteht.",
    "Before hanging up, show your appreciation for each other.":"Bevor Ihr auflegt, zeigt einander Eure Wertschätzung.",
    "Let us read the manual to you!": "Lass Dir die Anleitung von uns vorlesen!",
    "/// PLACES TO BREATHE":"/// RUHEORTE",
    "/// HELPFUL & MINDFUL LINKS": "/// SELBSTHILFE",
    "SUPPORT GROUP SEARCH": "SELBSTHILFEGRUPPEN SUCHEN",
    " // SUPPORT GROUPS // ": "// SELBSTHILFEGRUPPEN //",
    " // CRISIS HOTLINES // ": "// KRISENTELEFONE // ",
    " // COMMUNITY // ": "// COMMUNITY // ",
    " // CLASSES // ": "// KURSE //",
    " // GET ACTIVE // ": "// ENGAGEMENT //",
    "Central Path at Tempelhofer Feld": "Mittelweg Tempelhofer Feld",
    "Meadow at Körnerpark": "Wiese im Körnerpark",
    "St. Thomas / St. Michael Churchyard": "St. Thomas / St. Michael Kirchhof",
    "Benches at Comenius Gardens": "Bänke im Comenius Garten",
    "Von-der-Schulenburg-Park": "Von-der-Schulenburg-Park",
    "St. Jakobi Churchyard": " St. Jakobi Kirchhof",
    "Border Triangle at Landwehrkanal": "Dreiländereck am Landwehrkanal",
    "Rixdorfer Heights at Hasenheide": "Rixdorfer Höhe in der Hasenheide",
    "Plänterwald around Spreepark": "Plänterwald am Spreepark",
    "Steps of the Soviet War Memorial": "Treppe des Sowjetischen Ehrenmals",
    "Jetty on Insel der Jugend": "Steg auf der Insel der Jugend",
    "Tempelhofer Park Cemetery": "Tempelhofer Parkfriedhof",
    "THE PROJECT": "DAS PROJEKT",
    "For more than fourteen months now our lives have been on hold – locked down in a global crisis that has not only brought insecurity, instability and existential fears but also a state of constant physical isolation among us.": "Seit nunmehr vierzehn Monaten befinden wir uns in einer gesellschaftlichen Ausnahmesituation, die nicht nur Un- sicherheit, Instabilität und existenzielle Ängste mit sich gebracht hat, sondern uns auch rein körperlich anhaltend isoliert.",
    "Just like cultural life, our social ones have been paused. Yet, instead of quiet and inner peace, we feel constant pressure, stress and loneliness." : "Genau wie das kulturelle Leben ist auch das soziale fast zum Stillstand gekommen. Doch anstatt Stille und innere Ruhe zu finden, verspüren wir vor allem Stress, konstante Anspannung und Einsamkeit.",
    "The one thing that various exercises of mindfulness, strategies for resolving psychological anxiety, yoga or relaxation techniques share is the point from which they unfold: our breathing. In controlling and closely observing our breathing, we are able to engage with our body, our personal needs and emotions. We show appreciation for the incredible mechanism that keeps us alive – one that normally runs in the background by default. By actively appreciating our own breathing, we can come closer to ourselves and others." : "Was Achtsamkeitsübungen, Strategien zur Auflösung psychischer Angstzustände, Yoga- oder Entspannungs- techniken eint, ist ein gemeinsamer Ausgangspunkt: die eigene Atmung. Das bewusste Atmen ist immer auch eine bewusste Auseinandersetzung mit dem eigenen Körper, den eigenen Bedürfnissen, den eigenen Emotionen. Indem wir uns gezielt auf unsere Atmung konzentrieren und sie steuern, widmen wir uns dem eigenen Körper und schenken dem unglaublichen Mechanismus, der uns am Leben hält und normalerweise im Hintergrund abläuft, Aufmerksamkeit. Über diese Wertschätzung des Atmens kommen wir uns selbst näher und können uns auch anderen nähern.",
    "For this year's festival, we want to reclaim at least some of the inner trust and sense of control lost during the pandemic. We want to create a safe space for stability and mindfulness. Rather than looking at breathing solely as a mental or spiritual practice, the focus on the things we share. The temporary work ein/aus brings to mind a crisis phone. Based on this concept, it is a call for intimacy in a time of physical distance: We breathe together consciously and thus learn to appreciate a purely non-verbal mode of communication.": "Über die Dauer des diesjährigen Festivals wollen wir zumindest einen Teil des Vertrauens und der im Laufe der Pandemie verloren gegangenen Kontrolle zurückfordern, einen Ort der Sicherheit und des Bewusstmachens schaffen. Statt Atmung allein als mentale oder spirituelle Praxis zu betrachten, liegt der Fokus dabei auf der Gemeinsamkeit. Angelehnt ist die temporäre Arbeit ein/aus an das Konzept eines Krisentelefons. Ausgehend von einer telefonischen Hotline ist sie ein Aufruf zu Nähe in einer Zeit physischer Distanz: Indem wir bewusst und gemeinsam mit Anrufenden atmen, besinnen wir uns auf eine Kommunikationsform, die rein nonverbal stattfindet.",
    "ein/aus places the festival theme of air within a contemporary context – as an approach that sees breath as a life-bringing and imminently threatened appreciation of our body. At the same time, it can be used as a practical guide that brings together people all over Berlin, resolves the current state of isolation, and establishes a short-term network far beyond the cultural sphere. As the mobile number will be available for the festival weekend only, the hotline remains immaterial and yet is deeply connected to Neukölln.": "Nicht nur setzt sich ein/aus somit in den gegenwärtigen Kontext eingebunden mit dem Thema Luft auseinander – mit dem Atem als lebensbringende und akut bedrohte Wertschätzung des eigenen Körpers – sondern die Arbeit ist auch ganz konkret eine nutzbare Hilfestellung, die Menschen in ganz Berlin zusammenbringt, Isolationen auflöst und kurzfristig ein Netzwerk schafft, das über den kulturellen Be-reich hinausgeht. Die Mobilnummer wird nur über das Festivalwochenende erreichbar sein, die Hotline bleibt somit immateriell und ist doch verortet. Unabhängig davon, ob der Lockdown weiter anhält oder erneute Einschränkungen folgen, kann die Arbeit interaktiv und gemeinsam erlebt werden.",
    'and': 'und',
    'Web Development by Audrey Kadjar': 'Website programmiert von Audrey Kadjar',
    'CLOSE IMPRINT': 'IMPRESSUM SCHLIESSEN',
    'Imprint': 'Impressum',
    'Phone': 'Telefon'
  },
};

export default de;
