import React, { useState, useEffect } from "react";
/* eslint-disable @typescript-eslint/no-unused-vars */

//fade in
//if the user does not click after 5s goes to page
//fadeOutStartingScreen: () => void;

interface StartingScreenProps {
  goToInfo: () => void;
}

export function StartingScreen({ goToInfo }: StartingScreenProps) {
  const [germanText, setGermanText] = useState(true);
  const [englishText, setEnglishText] = useState(false);
  const [russianText, setRussianText] = useState(false);

  //use react router
  //redirect to home

  useEffect(() => {
    let isCancelled = false;

    setTimeout(() => {
      setGermanText(false);
      setEnglishText(true);
    }, 2500)

    setTimeout(() => {
      setEnglishText(false);
      setRussianText(true);
    }, 5000)


    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    let isCancelled = false;
    setTimeout(() => {
      //clearInterval(textSwitch)
      setGermanText(false);
      setEnglishText(false);
      setRussianText(false)
    }, 7500);
    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <div className="startingTextContainer">
      {germanText && (
        <>
          <div className="textBlock">
            <h1 className="startingText">
              Atme tief ein. <br /> Atme tief aus. <br />
            </h1>
            <br />

            <h1 className="startingText">
              Bist Du soweit? <br />
              <span className="startingText-underlined" onClick={goToInfo}>
                Los geht’s.{" "}
              </span>
            </h1>
          </div>
        </>
      )}

      {englishText && (
        <>
          <div className="textBlock">
            <h1 className="startingText">
              Take a deep breath in. <br /> Take a deep breath out. <br />
            </h1>
            <br />

            <h1 className="startingText">
              Are you ready? <br />
              <span className="startingText-underlined" onClick={goToInfo}>
                {" "}
                Off you go.{" "}
              </span>
            </h1>
          </div>
        </>
      )}

      {russianText && (
        <>
          <div className="textBlock">
            <h1 className="startingText">
                    Вдохните глубоко. <br /> Выдохните. <br />
            </h1>
            <br />

            <h1 className="startingText">
                Вы готовы?  <br />
              <span className="startingText-underlined" onClick={goToInfo}>
                    Поехали! 
              </span>
            </h1>
          </div>
        </>
      )}
    </div>
  );
}
