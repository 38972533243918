import React, { useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { StartingScreen } from "./StartingScreen";
import Info from "./Info";
import "./App.css";

function App() {
  const history = useHistory();

  const goToInfo = () => {
    history.push("/info");
    window.location.reload();
  };

  useEffect(() => {
    console.log('history.location.pathname', history.location.pathname)
    if (history.location.pathname === "/") {
      setTimeout(() => {
        history.push("/info");
        window.location.reload();
      }, 7501);
    }
  }, [history]);

  return (
    <>
      <Switch>
        <Route path="/info">
          <Info />
        </Route>

        <Route exact path="/">
          <StartingScreen goToInfo={goToInfo} />
        </Route>
      </Switch>
    </>
  );
}

export default App;
